/***** Projects *****/

.section-projects-bnr {
  position: relative;
  z-index: 0;
  @include max($lg) {
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-top: 278px;
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($lg) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.section-projects {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include max($lg) {
    margin-bottom: 40px;
  }
  .container {
  }
  .projects-i {
  }

  .page-nav {
    ul {
      li {
        > a,
        > span {
          padding: 38px 28px;
          letter-spacing: 0;
          @include max($xxxl) {
            padding: 30px 20px;
            letter-spacing: 0;
          }
          @include max($lg) {
            padding: 15px 20px;
          }
        }
        > a {
          font-weight: 600;
        }
      }
    }
  }

  .projects-body {
    padding: 135px 0 165px;
    position: relative;
    z-index: 0;
    @include max($lg) {
      padding: 60px 0;
    }
    @include max($sm) {
      padding: 30px 0;
    }
  }
  .projects-t {
    margin-bottom: 130px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    @include max($xxl) {
      margin-bottom: 100px;
    }
    @include max($lg) {
      margin-bottom: 80px;
      display: block;
    }
    @include max($sm) {
      margin-bottom: 60px;
    }
    h1 {
      width: 25%;
      padding-right: 30px;
      @include max($lg) {
        width: 100%;
        padding: 0;
        margin: 0 0 40px;
      }
      @include max($sm) {
        margin-bottom: 30px;
      }
    }

    .brief {
      flex: 1;
    }

    .sq-1 {
      width: 1118px;
      height: 1118px;
      color: #f3f8fa;
      opacity: 0.7;
      position: absolute;
      z-index: -1;
      left: -485px;
      top: -592px;
      @include max($sm) {
        width: 474px;
        height: 474px;
        top: -97px;
        left: -157px;
      }
    }
  }

  .projects-filter {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    margin-bottom: 235px;
    @include max($xxl) {
      margin-bottom: 100px;
    }
    @include max($lg) {
      margin-bottom: 100px;
      display: block;
    }
    .label {
      display: flex;
      align-items: center;
      flex: 0 0 280px;
      margin-right: 10px;
      justify-content: flex-end;
      @include max($xxl) {
        flex: 0 0 auto;
      }
      @include max($lg) {
        margin: 0 0 20px;
        justify-content: flex-start;
      }

      .icon {
        margin-right: 16px;
      }
      span {
        font-size: 24px;
        color: $blue;
        @include max($xxl) {
          font-size: 20px;
        }
      }
    }
    .filter-nav {
      flex: 0 0 auto;
      position: relative;
      z-index: 1;
      @include max($xxl) {
        flex: 1;
      }
      ul {
        border-bottom: 2px solid rgba($blue, 0.5);
        position: relative;
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        @include max($lg) {
          border: none;
          flex-wrap: wrap;
        }
        li {
          > a,
          > span {
            padding: 30px 25px;
            display: block;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 20px;
            letter-spacing: 0.02em;
            @include max($xxl) {
              padding: 20px 15px;
              letter-spacing: 0;
              font-size: 16px;
            }
            @include max($lg) {
              padding: 5px 0;
              font-size: 14px;
              margin: 0 15px 10px 0;
            }
          }
          > a {
            color: $blueb;
            &:hover {
              color: $blue;
            }
          }
          &.active {
            span {
              color: $blue;
              font-weight: bold;
              position: relative;
              cursor: default;
              &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 5px;
                background: $blue;
                @include max($lg) {
                  height: 3px;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
      &.active {
        ul {
          @include max($lg) {
            opacity: 1;
            visibility: visible;
            max-height: 500px;
          }
        }
      }
    }
    .sq-2 {
      width: 41px;
      height: 41px;
      color: $blue;
      opacity: 0.1;
      position: absolute;
      bottom: -64px;
      right: 50px;
      @include max($sm) {
        top: 260px;
        right: 0;
      }
    }
  }

  .projects-list {
    position: relative;
    z-index: 1;
    .project {
      display: flex;
      align-items: center;
      position: relative;
      @include max($sm) {
        flex-direction: column;
      }
      &:not(:last-child) {
        margin-bottom: 200px;
        @include max($lg) {
          margin-bottom: 150px;
        }
        @include max($sm) {
          margin-bottom: 50px;
        }
      }
      .project-img {
        width: 703px;
        flex: 0 0 703px;
        height: 703px;
        display: flex;
        align-items: center;
        @include max($xxl) {
          width: 45%;
          flex: 0 0 45%;
          height: auto;
        }
        @include max($sm) {
          order: 2;
          align-self: flex-end;
          width: 65%;
          flex: 0 0 auto;
          margin-right: -15%;
        }
      }
      .project-info {
        flex: 1;
        @include max($sm) {
          order: 1;
        }
        .project-date {
          font-size: 24px;
          color: $blue;
          margin-bottom: 36px;
          @include max($lg) {
            font-size: 20px;
            margin-bottom: 20px;
          }
          @include max($sm) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        .project-title {
          font-size: 36px;
          line-height: 1.2;
          color: $blue;
          margin-bottom: 20px;
          @include max($lg) {
            margin-bottom: 15px;
            font-size: 30px;
          }
          @include max($sm) {
            margin-bottom: 10px;
            font-size: 24px;
          }
          br {
            @include max($lg) {
            }
          }
        }
        .project-desc {
          margin-bottom: 65px;
          @include max($lg) {
            margin-bottom: 40px;
          }
          @include max($sm) {
            margin-bottom: 30px;
          }
        }
        .page-more-link {
          font-size: 24px;
          line-height: 1.1;
          @include max($lg) {
            font-size: 20px;
          }
          @include max($sm) {
            font-size: 18px;
          }
          span {
            display: block;
          }
        }
      }
      .sq-svg {
        width: 869px;
        height: 869px;
        color: #f3f8fa;
        position: absolute;
        top: -83px;
        left: 0;
        z-index: -1;
        @include max($xxl) {
          width: 80vw;
          top: 50%;
          left: -10%;
          transform: translateY(-50%);
        }
        @include max($lg) {
          width: 100%;
        }
        @include max($sm) {
          height: 80vw;
        }
      }
      &:nth-child(2n + 1) {
        .project-info {
          padding: 0 70px 0 55px;
          @include max($xxl) {
            padding: 0 35px 0 100px;
          }
          @include max($md) {
            padding: 0 20px 0 60px;
          }
          @include max($sm) {
            padding: 0 0 10px;
          }
        }
        .sq-svg {
          left: 410px;
          @include max($xxl) {
            left: 37%;
          }
        }
      }
      &:nth-child(2n) {
        .project-img {
          order: 2;
        }
        .project-info {
          order: 1;
          padding: 0 35px 0 235px;
          @include max($xxl) {
            padding: 0 35px 0 100px;
          }
          @include max($md) {
            padding: 0 20px 0 60px;
          }
          @include max($sm) {
            padding: 0 0 10px;
          }
        }
        .sq-svg {
          @include max($sm) {
            left: 37%;
          }
        }
      }
    }
  }

  .load-more {
    margin: 83px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    gap: 32px;
    @include max($xxl) {
      margin: 60px 0;
      gap: 20px;
    }
    @include max($sm) {
      margin: 40px -20px;
      gap: 10px;
    }
    a {
      width: 293px;
      height: 293px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-align: center;
      font-size: 36px;
      line-height: 1;
      padding-top: 20px;
      @include max($xxl) {
        width: 200px;
        height: 200px;
        font-size: 24px;
        padding-top: 15px;
      }
      @include max($sm) {
        width: 150px;
        height: 150px;
        font-size: 18px;
        padding-top: 10px;
      }
      span {
        display: block;
      }
      .sq-load-3 {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $blue4;
        transition: all 0.2s;
      }
      &:hover {
        .sq-load-3 {
          color: $blue;
        }
      }
    }
    .sq-load-1,
    .sq-load-5 {
      width: 55px;
      height: 55px;
      color: #94c4d5;
      @include max($xxl) {
        width: 40px;
        height: 40px;
      }
      @include max($sm) {
        width: 24px;
        height: 24px;
      }
    }
    .sq-load-2,
    .sq-load-4 {
      width: 134px;
      height: 134px;
      color: rgba(#015993, 0.1);
      @include max($xxl) {
        width: 100px;
        height: 100px;
      }
      @include max($sm) {
        width: 60px;
        height: 60px;
      }
    }
  }
}
