/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1340px;
}

body {
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
}

.main {
  position: relative;
  z-index: 0;
  flex: 1;
  @include max($sm) {
    padding-top: 58px;
  }
}
