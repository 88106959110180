/***** Header *****/

.header {
  height: 99px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: stretch;
  @include max($xxxl) {
    height: 83px;
  }
  @include max($sm) {
    height: 58px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #072a53;
  }

  .container {
    display: flex;
    align-items: stretch;
    position: relative;
    @include max($sm) {
      padding: 0 18px;
    }
  }
  .header-i {
    display: flex;
    align-items: stretch;
    width: 100%;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      left: 100%;
      width: 100vw;
      background: rgba(#fff, 0.5);
      @include max($sm) {
        display: none;
      }
    }
  }
  .logo {
    margin: 31px 76px 0 0;
    width: 255px;
    flex: 0 0 255px;
    position: relative;
    z-index: 1;
    @include max($xxxl) {
      width: 220px;
      flex: 0 0 220px;
      margin: 15px 50px 0 0;
    }
    @include max($sm) {
      width: 146px;
      flex: 0 0 146px;
      margin: 12px 30px 0 0;
    }
    a {
    }
    img {
      width: 100%;
    }
  }
  .header-r {
    flex: 1;
    border-bottom: 2px solid rgba(#fff, 0.5);
    // position: relative;
    z-index: 1;
    @include max($lg) {
      display: flex;
      align-items: center;
    }
    @include max($sm) {
      border: none;
    }
  }
  .header-nav {
    display: flex;
    align-items: center;
    @include max($lg) {
      opacity: 0;
      visibility: hidden;
      // max-height: 0;
      transform: translateY(-10px);
      transition: all 0.2s;
      position: absolute;
      top: 100%;
      width: 300px;
      right: -30px;
      display: block;
      background: rgba($blue, 0.98);
      padding: 10px 0;
    }
    @include max($sm) {
      width: auto;
      left: 0;
      right: 0;
      top: 58px;
      bottom: 0;
      position: fixed;
      overflow-y: auto;
    }
    &.active {
      @include max($lg) {
        // max-height: 400px;
        opacity: 1;
        visibility: visible;
        transform: none;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      @include max($lg) {
        display: block;
      }

      li {
        display: block;
        &:not(:last-child) {
        }
        a,
        span {
          padding: 38px;
          display: block;
          line-height: 20px;
          letter-spacing: 0.04em;
          @include max($xxxl) {
            padding: 30px 20px;
            letter-spacing: 0;
          }
          @include max($lg) {
            padding: 16px 20px;
            font-size: 16px;
          }
        }
        a {
          color: #fff;
          &:hover {
            opacity: 0.8;
          }
        }
        span {
          position: relative;
          display: block;
          color: #fff;
          cursor: default;
          @include max($lg) {
            background: rgba(#fff, 0.15);
          }
          &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 5px;
            background: #fff;
            @include max($lg) {
              display: none;
            }
          }
        }
        ul.sub-menu {
          display: none;
          padding: 0;
          margin: 0;
          list-style: none;
          background: rgba(#fff, 0.1);

          li {
            a,
            span {
              padding: 13px 20px 13px 40px;
              font-size: 13px;
            }
            span {
              background: rgba(#fff, 0.25);
              &:after {
                display: none;
              }
            }
          }
        }
        &.active-trail {
          > a {
          }
          ul.sub-menu {
            @include max($lg) {
              display: block;
            }
          }
        }
      }
    }
    .header-lang {
      margin-left: auto;
      line-height: 20px;
      @include max($lg) {
        margin: 20px 0 0;
        font-size: 16px;
      }
      a {
        color: rgba(#fff, 0.8);
        font-weight: bold;
        text-transform: uppercase;
        @include max($lg) {
          padding: 15px 20px;
          display: block;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .header-nav-toggler {
    display: none;
    cursor: pointer;
    transition: all 0.2s;
    padding: 5px;
    margin: 0 -5px 0 auto;
    line-height: 0;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    @include max($lg) {
      display: flex;
    }
    &:hover {
      opacity: 0.8;
    }
    .icon {
    }
    &.active {
      background: $blueb;
      border-radius: 5px;
      opacity: 1;
    }
  }

  .header-bg-l {
    position: absolute;
    left: -129px;
    top: -229px;
    width: 507px;
    height: 507px;
    background: url("../images/el/header-l-bg.svg") 0 0 no-repeat;
    background-size: contain;
    @include max($xxxl) {
      width: 400px;
      height: 400px;
      left: -100px;
      top: -190px;
    }
    @include max($lg) {
      left: -90px;
    }
    @include max($sm) {
      top: -81px;
      left: -20px;
      width: 218px;
      height: 139px;
      background-size: cover;
    }
  }
  .header-bg-r {
    position: absolute;
    right: -207px;
    top: -106px;
    width: 309px;
    height: 309px;
    color: $blueb;
    opacity: 0.25;
    z-index: -1;
    @include max($lg) {
    }
    @include max($sm) {
      opacity: 1;
      width: 98px;
      height: 98px;
      top: -32px;
      right: -20px;
    }
  }
}
