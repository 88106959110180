/***** About *****/

.section-about-bnr {
  background: url("../images/il/bnr-home.jpg") 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  @include max($lg) {
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-top: 278px;
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($lg) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.section-about {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .container {
  }
  .about-i {
  }
  .about-nav {
    // padding-right: 140px;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 100%;
      width: 100vw;
      height: 2px;
      background: rgba($blue, 0.5);
      @include max($lg) {
        display: none;
      }
    }
    ul {
      border-bottom: 2px solid rgba($blue, 0.5);
      position: relative;
      display: inline-flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      @include max($xxxl) {
        display: flex;
      }
      @include max($lg) {
        display: none;
      }
      li {
        > a,
        > span {
          padding: 38px;
          display: block;
          font-weight: 600;
          font-size: 18px;
          text-transform: uppercase;
          line-height: 20px;
          letter-spacing: 0.02em;
          @include max($xxxl) {
            padding: 30px 20px;
            letter-spacing: 0;
          }
          @include max($lg) {
            padding: 15px 20px;
          }
        }
        > a {
          color: $blueb;
          &:hover {
            color: $blue;
          }
        }
        &.active {
          span {
            color: $blue;
            font-weight: bold;
            position: relative;
            cursor: default;
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              height: 5px;
              background: $blue;
            }
          }
        }
      }
    }
    &.active {
      ul {
        @include max($lg) {
          opacity: 1;
          visibility: visible;
          max-height: 500px;
        }
      }
    }
  }

  .about-body {
    padding: 135px 0 165px;
    @include max($lg) {
      padding: 60px 0;
    }
    @include max($sm) {
      padding: 30px 0;
    }
  }

  .about-block-1 {
    margin-bottom: 10px;
    .row {
      [class*="col-"] {
        position: relative;
        &:nth-child(2) {
          padding-top: 25px;
          @include max($sm) {
            padding-top: 0;
          }
          h2 {
            @include max($sm) {
              margin-left: 150px;
            }
          }
        }
      }
    }

    h2,
    .h2 {
      color: $blue;
      margin-bottom: 50px;
      @include max($lg) {
        margin-bottom: 40px;
      }
      @include max($sm) {
        margin-bottom: 30px;
      }
    }
    .brief {
      font-size: 30px;
      font-weight: 300;
      max-width: 365px;
      line-height: 1.25;
      @include max($lg) {
        font-size: 25px;
        max-width: 700px;
      }
      @include max($sm) {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }

    .about-info {
      max-width: 500px;
      margin-bottom: 110px;
      @include max($lg) {
        max-width: 700px;
      }
      @include max($sm) {
        margin-bottom: 20px;
      }
    }

    .txt {
      h2 {
        margin: 0 0 45px;
      }
    }

    .sq-1 {
      width: 1118px;
      height: 1118px;
      color: #f3f8fa;
      opacity: 0.7;
      position: absolute;
      z-index: -1;
      left: -470px;
      top: -592px;
      @include max($sm) {
        width: 474px;
        height: 474px;
        top: -97px;
        left: -157px;
      }
    }
    .sq-img-1 {
      width: 529px;
      height: 529px;
      margin-top: -10px;
      position: absolute;
      z-index: -2;
      top: 310px;
      left: -640px;
      @include max($sm) {
        width: 244px;
        height: 244px;
        margin: 0 0 -70px -87px;
        position: relative;
        top: auto;
        left: auto;
      }
    }
    .sq-2 {
      color: #94c4d5;
      width: 55px;
      height: 55px;
      position: absolute;
      top: -5px;
      left: -10px;
      z-index: -1;
      @include max($sm) {
        top: -13px;
        left: -10px;
      }
    }
    .sq-3 {
      width: 134px;
      height: 134px;
      color: $blue;
      opacity: 0.1;
      position: absolute;
      top: 122px;
      left: -85px;
      @include max($sm) {
        width: 134px;
        height: 134px;
        top: 410px;
        left: -70px;
      }
    }
    .sq-4 {
      width: 41px;
      height: 41px;
      color: $blue;
      opacity: 0.1;
      position: absolute;
      right: 65px;
      top: 275px;
      @include max($sm) {
        top: 260px;
        right: 0;
      }
    }
  }

  .about-more {
    display: flex;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $blue;
      @include max($lg) {
        font-size: 18px;
      }
      @include max($sm) {
        font-size: 14px;
      }
      &:hover {
        color: $blueb;
        .icon-w {
          opacity: 0.9;
        }
      }
    }
    .icon-w {
      width: 169px;
      height: 169px;
      background: url("../images/el/square-blue.svg") 50% 50% no-repeat;
      flex: 0 0 169px;
      margin-right: 30px;
      transition: all 0.2s;
      @include max($lg) {
        width: 100px;
        flex: 0 0 100px;
        margin-right: 20px;
      }
      @include max($sm) {
        width: 86px;
        height: 86px;
        flex: 0 0 86px;
        margin-right: 16px;
      }
      .icon {
        @include max($lg) {
          width: 46%;
        }
        @include max($sm) {
        }
      }
    }
  }
}

.section-sectors {
  position: relative;
  margin: -110px 0 -75px;
  @include max($sm) {
    margin: 0;
  }

  .container {
    position: relative;
    z-index: 2;
  }
  .sectors-i {
    padding: 355px 0 160px;
    @include max($lg) {
      padding: 260px 0 160px;
    }
    @include max($sm) {
      padding: 225px 0 160px;
    }
    h2 {
      color: $blue;
      margin: 0 0 103px;
      @include max($lg) {
        margin-bottom: 70px;
      }
      @include max($sm) {
        max-width: 70%;
        margin-bottom: 110px;
      }
    }
    .sectors-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -140px 0 0;
      @include max($xxxl) {
        margin: 0;
        display: block;
      }
      .item {
        width: 510px;
        flex: 0 0 510px;
        display: flex;
        align-items: center;
        padding: 0 0 45px;
        @include max($xxxl) {
          position: relative;
          width: 100%;
          padding: 0;
          margin-bottom: 0;
        }
        @include max($lg) {
          margin-bottom: 20px;
        }
        @include max($sm) {
          display: block;
          margin-bottom: 45px;
        }
        .item-icon {
          background: url("../images/el/square-blue-transp.svg") 50% 50%
            no-repeat;
          background-size: contain;
          width: 169px;
          flex: 0 0 169px;
          height: 169px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          @include max($lg) {
            margin: 0;
          }
          .icon {
            width: 80px;
            height: 80px;
          }
        }
        .item-title {
          flex: 1;
          font-size: 24px;
          line-height: 1.2;
          color: $blue;
          @include max($xxxl) {
          }
          @include max($lg) {
            padding-left: 30px;
          }
          @include max($sm) {
            position: absolute;
            max-width: 60%;
            padding: 0;
            bottom: 0;
          }
          a {
            color: $blue;
            &:hover {
              color: $blueb;
            }
          }
        }
        &:nth-child(2n) {
          .item-icon {
            @include max($xxxl) {
              margin-left: 300px;
            }
            @include max($lg) {
              margin-left: 150px;
            }
            @include max($sm) {
              margin-left: auto;
            }
          }
          .item-title {
            left: 0;
          }
        }
        &:nth-child(2n + 1) {
          .item-icon {
            @include max($lg) {
              margin-right: auto;
            }
          }
          .item-title {
            @include max($lg) {
            }
            @include max($sm) {
              right: 0;
              text-align: right;
            }
          }
        }
        &:nth-child(1) {
          margin-left: 168px;
          @include max($xxxl) {
            margin-left: 0;
          }
          @include max($lg) {
            margin-left: 0;
          }
          .item-icon {
          }
          .item-title {
          }
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
          margin-left: 385px;
          @include max($xxxl) {
            margin-left: 0;
          }
          @include max($lg) {
            margin-left: 0;
          }
        }
        &:nth-child(4) {
        }
        &:nth-child(5) {
          margin-left: 168px;
          @include max($xxxl) {
            margin-left: 0;
          }
          @include max($lg) {
            margin-left: 0;
          }
        }
        &:nth-child(6) {
        }
      }
    }
  }

  .sqs {
    position: absolute;
    z-index: 0;
    background: url("../images/el/squares-bg-2.svg") 50% 50% no-repeat;
    background-size: contain;
    top: 0px;
    width: 2282px;
    height: 1331px;
    bottom: 396px;
    left: 50%;
    transform: translateX(-48.5%);
    @include max($sm) {
      background: url("../images/el/squares-bg-2-m.svg") 50% 50% no-repeat;
      background-size: contain;
      width: 591px;
      height: 1757px;
      bottom: auto;
      top: 30px;
      transform: translateX(-55%);
    }
  }

  .sq-img {
    width: 529px;
    height: 529px;
    position: absolute;
    z-index: 1;
    top: 38px;
    right: -96px;

    @include max($lg) {
      width: 300px;
      height: 300px;
      right: -30px;
    }
    @include max($sm) {
      width: 272px;
      height: 272px;
      top: 0;
      right: -59px;
    }
  }

  &.section-sectors-2 {
    margin: -220px 0 -120px;
    z-index: 0;
    h2 {
      max-width: 25%;
      margin-bottom: 60px;
      @include max($lg) {
        max-width: 100%;
      }
    }
  }
  &.section-sectors-3 {
    margin: -220px 0 0;
    padding-bottom: 165px;
    z-index: 0;
    h2 {
      max-width: 25%;
      margin-bottom: 60px;
      @include max($lg) {
        max-width: 100%;
      }
    }
  }
}

.section-about-more {
  padding: 0 0 165px;
  .container {
  }
  .about-more-i {
  }
  h2 {
    color: $blue;
    margin-bottom: 40px;
    @include max($sm) {
      margin-bottom: 30px;
    }
  }
  .txt {
    max-width: 620px;
    margin-bottom: 45px;
    @include max($lg) {
      margin-bottom: 30px;
    }
    @include max($sm) {
      margin-bottom: 20px;
    }
    p {
    }
  }
  .about-more-link {
    color: $blue;
    font-size: 24px;
    @include max($lg) {
      font-size: 18px;
    }
    @include max($sm) {
      font-size: 14px;
    }
    a {
      color: $blue;
      font-weight: 600;
      &:hover {
        color: $blueb;
      }
    }
  }
}
