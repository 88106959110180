/***** Sector *****/

.section-sector-bnr {
  position: relative;
  z-index: 0;
  @include max($lg) {
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-top: 278px;
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($lg) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.section-sector {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include max($lg) {
    margin-bottom: 40px;
  }
  .container {
  }
  .sector-i {
  }

  .page-nav {
    ul {
      justify-content: space-between;
      li {
        > a,
        > span {
          padding: 38px 15px;
          letter-spacing: 0;
          @include max($xxxl) {
            padding: 30px 10px;
            letter-spacing: 0;
          }
          @include max($lg) {
            padding: 15px 20px;
          }
        }
        > a {
          font-weight: 600;
        }
      }
    }
  }

  .sector-body {
    padding: 135px 0 165px;
    position: relative;
    z-index: 0;
    @include max($lg) {
      padding: 60px 0;
    }
    @include max($sm) {
      padding: 30px 0;
    }
  }
  .sector-t {
    margin-bottom: 200px;
    position: relative;
    z-index: 1;
    @include max($lg) {
      margin-bottom: 100px;
    }
    @include max($sm) {
      margin-bottom: 80px;
    }
    .sector-t-h {
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;
      @include max($lg) {
        margin-bottom: 40px;
      }
      @include max($sm) {
        display: block;
        margin-bottom: 30px;
      }
      .sector-t-content {
        width: 50%;
        flex: 0 0 50%;
        margin-right: 30px;
        @include max($lg) {
          width: auto;
          flex: 1;
        }
        @include max($sm) {
          width: 100%;
          margin-bottom: 20px;
        }
        h1 {
          color: $blue;
          margin-bottom: 65px;
          @include max($lg) {
            margin-bottom: 40px;
          }
          @include max($sm) {
            margin-bottom: 30px;
          }
        }
        .brief {
          font-size: 30px;
          font-weight: 300;
          max-width: 960px;
          line-height: 1.25;
          @include max($lg) {
            font-size: 25px;
            max-width: 700px;
          }
          @include max($sm) {
            font-size: 22px;
          }
        }
      }
      .sector-t-icon {
        margin: -40px 0 0 auto;
        background: url("../images/el/square-blue-transp.svg") 50% 50% no-repeat;
        background-size: contain;
        width: 294px;
        flex: 0 0 294px;
        height: 294px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include max($lg) {
          margin: 0;
          width: 169px;
          flex: 0 0 169px;
          height: 169px;
        }
        @include max($sm) {
          margin: 0 auto;
        }
        .icon {
          width: 138px;
          height: 138px;
          @include max($lg) {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    .sector-t-desc {
      margin-left: 25%;
      @include max($lg) {
        margin-left: 0;
      }
    }

    .sq-1 {
      width: 1118px;
      height: 1118px;
      color: #f3f8fa;
      opacity: 0.7;
      position: absolute;
      z-index: -1;
      left: -485px;
      top: -592px;
      @include max($sm) {
        width: 474px;
        height: 474px;
        top: -97px;
        left: -157px;
      }
    }
    .sq-2 {
      width: 41px;
      height: 41px;
      color: $blue;
      opacity: 0.1;
      position: absolute;
      right: 65px;
      top: 275px;
      @include max($sm) {
        top: 260px;
        right: 0;
      }
    }
  }

  .sector-b {
    position: relative;
    z-index: 0;
    .item {
      display: flex;
      margin: 0 -10px;
      position: relative;

      @include max($sm) {
        display: block;
        margin: 0;
      }
      &:not(:last-child) {
        margin-bottom: 150px;
        @include max($lg) {
          margin-bottom: 80px;
        }
        @include max($sm) {
          margin-bottom: 60px;
        }
      }
      .item-info {
        width: 50%;
        flex: 0 0 50%;
        padding: 10px;
        position: relative;
        z-index: 1;
        @include max($lg) {
          width: 70%;
          flex: 0 0 70%;
        }
        @include max($sm) {
          width: 100%;
          padding: 0;
          margin-bottom: 20px;
        }
      }
      .item-logo {
        line-height: 0;
        margin-bottom: 40px;
        @include max($lg) {
          margin-bottom: 30px;
        }
        @include max($sm) {
          margin-bottom: 20px;
        }
        img {
        }
      }
      .item-title {
        h2 {
          margin-bottom: 40px;
          color: $blue;
          @include max($lg) {
            margin-bottom: 30px;
          }
          @include max($sm) {
            margin-bottom: 20px;
          }
        }
      }
      .item-desc {
        margin-bottom: 50px;
        @include max($lg) {
          margin-bottom: 40px;
        }
        @include max($sm) {
          margin-bottom: 30px;
        }
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .page-more-link {
      }

      .item-img {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 10px;
        position: relative;
        z-index: 1;
        @include max($lg) {
          margin: 120px 0 0;
          width: 30%;
          flex: 0 0 30%;
        }
        @include max($sm) {
          padding: 0;
          width: 50%;
          margin: 0 auto;
        }
      }

      .sq-svg {
        width: 1198px;
        height: 1198px;
        color: #f3f8fa;
        position: absolute;
        z-index: 0;
      }

      &:nth-child(2n + 1) {
        .sq-svg {
          top: -310px;
          left: -370px;
        }
      }
      &:nth-child(2n) {
        .item-info {
          order: 2;
        }
        .item-img {
          order: 1;
          margin-top: 30px;
        }
        .sq-svg {
          top: -290px;
          right: -180px;
        }
      }
    }
  }
}

.section-sector-more {
  padding: 0 0 200px;
  position: relative;
  z-index: 1;
  .h2 {
    color: $blue;
    margin-bottom: 10px;
  }
}
