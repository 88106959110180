/* Color */
$blue4: #061847;
$blue: #015993;
$blueb: #4e93bb;
$blued: #94c4d5;

$font-heading: "Open Sans", sans-serif;
$font-basic: "Open Sans", sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
