/***** Components, common blocks *****/

.bnr-top {
  position: relative;
  height: 639px;
  display: flex;
  align-items: stretch;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  @include max($xxxl) {
    height: 550px;
  }
  @include max($lg) {
    height: 57.6vw;
  }
  @include max($sm) {
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 431px;
    background: linear-gradient(to bottom, #002e62 0%, rgba(#002e62, 0) 100%);
    z-index: 1;
    @include max($sm) {
      display: none;
    }
  }
  .container {
    display: flex;
    align-items: flex-end;
    z-index: 2;
  }
  .bnr-top-i {
    width: 100%;
    text-align: center;
    padding: 0 0 100px;
    z-index: 1;
    position: relative;
    color: #fff;
    @include max($lg) {
      padding-bottom: 80px;
    }
    @include max($sm) {
      padding-bottom: 60px;
    }
    h1 {
      margin: 0 0 25px;
      color: #fff;
    }
    p {
      font-size: 24px;
      margin: 0;
      @include max($lg) {
        font-size: 20px;
      }
      @include max($sm) {
        font-size: 16px;
      }
    }
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($lg) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.page-nav {
  // padding-right: 140px;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 100vw;
    height: 2px;
    background: rgba($blue, 0.5);
    @include max($lg) {
      display: none;
    }
  }
  ul {
    border-bottom: 2px solid rgba($blue, 0.5);
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    @include max($xxxl) {
      display: flex;
    }
    @include max($lg) {
      display: none;
    }
    li {
      > a,
      > span {
        padding: 38px;
        display: block;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 20px;
        letter-spacing: 0.02em;
        @include max($xxxl) {
          padding: 30px 20px;
          letter-spacing: 0;
        }
        @include max($lg) {
          padding: 15px 20px;
        }
      }
      > a {
        color: $blueb;
        &:hover {
          color: $blue;
        }
      }
      &.active {
        span {
          color: $blue;
          font-weight: bold;
          position: relative;
          cursor: default;
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 5px;
            background: $blue;
          }
        }
      }
    }
  }
  &.active {
    ul {
      @include max($lg) {
        opacity: 1;
        visibility: visible;
        max-height: 500px;
      }
    }
  }
}

.page-more-link {
  color: $blue;
  font-size: 24px;
  @include max($lg) {
    font-size: 18px;
  }
  @include max($sm) {
    font-size: 14px;
  }
  a {
    color: $blue;
    font-weight: 600;
    &:hover {
      color: $blueb;
    }
  }
}
