/***** About - Culture-Social *****/

.section-about2-bnr {
  background-size: cover;
  position: relative;
  z-index: 0;
  @include max($lg) {
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-top: 278px;
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($lg) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.section-about2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .container {
  }
  .about2-i {
  }
  .page-nav {
  }

  .about2-body {
    padding: 135px 0 185px;
    position: relative;
    z-index: 0;
    @include max($lg) {
      padding: 60px 0 165px;
    }
    @include max($sm) {
      padding: 30px 0 165px;
    }
  }

  .about2-block-1 {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;
    @include max($lg) {
      display: block;
    }
    .about2-t {
      padding: 0 10px;
      width: 25%;
      flex: 0 0 25%;
      @include max($lg) {
        width: 100%;
        margin-bottom: 30px;
      }
      @include max($sm) {
        margin-bottom: 20px;
      }
      h1 {
        margin-bottom: 68px;
        @include max($lg) {
          margin-bottom: 40px;
        }
        @include max($sm) {
          margin-bottom: 30px;
        }
      }
      .brief {
        font-size: 30px;
        font-weight: 300;
        max-width: 365px;
        line-height: 1.25;
        @include max($lg) {
          font-size: 25px;
          max-width: 700px;
        }
        @include max($sm) {
          font-size: 22px;
          margin-bottom: 40px;
        }
      }
    }
    .block-info {
      flex: 1;
      padding: 0 10px;
      h2 {
        margin-top: 45px;
      }
      ol {
      }
    }
  }

  .sq-1 {
    width: 1118px;
    height: 1118px;
    color: #f3f8fa;
    opacity: 0.7;
    position: absolute;
    z-index: -1;
    left: -485px;
    top: -592px;
    @include max($sm) {
      width: 474px;
      height: 474px;
      top: -97px;
      left: -157px;
    }
  }
  .sq-2 {
    width: 134px;
    height: 134px;
    color: $blue;
    opacity: 0.1;
    position: absolute;
    top: 264px;
    right: 130px;
    @include max($sm) {
    }
  }
  .sq-3 {
    width: 41px;
    height: 41px;
    color: $blue;
    opacity: 0.1;
    position: absolute;
    top: 419px;
    right: 50px;
    @include max($sm) {
    }
  }

  .about2-block-2 {
    margin: 65px -10px 65px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: stretch;
    @include max($md) {
      flex-direction: column;
      margin-top: 20px;
    }
    .block-img {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 10px;
      position: relative;
      @include max($md) {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }
    }
    .sq-img.sq-img-1 {
      width: 850px;
      height: 850px;
      position: absolute;
      top: 57%;
      right: 65px;
      transform: translateY(-50%);
      @include max($xxl) {
        width: 600px;
        height: 600px;
        top: 50%;
        right: 20px;
      }
      @include max($lg) {
        width: 450px;
        height: 450px;
      }
      @include max($md) {
        width: 50vw;
        height: 50vw;
        position: relative;
        top: auto;
        right: auto;
        transform: none;
      }
      @include max($xs) {
        width: 80vw;
        height: 80vw;
      }
    }
    .block-info {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 10px;
      @include max($md) {
        width: 100%;
      }
      h2 {
        margin-bottom: 65px;
        @include max($lg) {
          margin-bottom: 40px;
        }
        @include max($sm) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .about2-block-3 {
    .about2-support-h {
      position: relative;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 170px;
      @include max($sm) {
        margin-bottom: 220px;
      }
      .about2-support-h-sq {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 450px;
        height: 450px;
        z-index: 3;
        @include max($lg) {
          width: 300px;
          height: 300px;
        }
        @include max($sm) {
          width: 240px;
          height: 240px;
        }
      }
      h2 {
        color: #fff;
      }
      .sq-su-lg {
        color: #072a53;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .sq-su-md {
        width: 134px;
        height: 134px;
        color: #015993;
        opacity: 0.1;
        position: absolute;
        z-index: 1;
        &.sq-su-md-1 {
          top: -167px;
          left: 500px;
          @include max($lg) {
            left: 30%;
          }
        }
        &.sq-su-md-2 {
          top: 175px;
          left: 325px;
          @include max($lg) {
            left: 45%;
          }
        }
        &.sq-su-md-3 {
          top: 450px;
          left: 672px;
          @include max($lg) {
            left: 55%;
          }
        }
      }

      .sq-su-sm {
        width: 54px;
        height: 54px;
        color: #94c4d5;
        position: absolute;
        z-index: 2;
        &.sq-su-sm-1 {
          top: 345px;
          left: 104px;
          @include max($lg) {
            left: 0%;
          }
        }
        &.sq-su-sm-2 {
          top: 422px;
          left: 388px;
          @include max($lg) {
            left: 30%;
          }
        }
        &.sq-su-sm-3 {
          top: -27px;
          left: 495px;
          @include max($lg) {
            left: 35%;
          }
        }
        &.sq-su-sm-4 {
          top: 0;
          left: 837px;
          @include max($lg) {
            left: 60%;
          }
        }
        &.sq-su-sm-5 {
          top: 319px;
          left: 864px;
          @include max($lg) {
            left: 65%;
          }
        }
        &.sq-su-sm-6 {
          top: 148px;
          left: 1125px;
          @include max($lg) {
            right: 0;
          }
        }
      }

      .sq-su-hg {
        width: 1198px;
        height: 1198px;
        color: #f3f8fa;
        z-index: 0;
        position: absolute;
        top: -166px;
        right: -756px;
      }

      .sq-img-2 {
        width: 450px;
        height: 450px;
        position: absolute;
        z-index: 2;
        bottom: -175px;
        right: -200px;
        @include max($lg) {
          width: 300px;
          height: 300px;
          right: -100px;
        }
        @include max($sm) {
          right: -100px;
          bottom: -230px;
        }
      }
    }
    .about2-support-content {
      position: relative;
      z-index: 1;
      .row {
        [class*="col-"] {
        }
      }
      .txt {
        h3 {
          color: $blue;
          font-weight: normal;
          margin: 85px 0 45px;
          @include max($lg) {
            margin: 50px 0 30px;
          }
          @include max($sm) {
            margin: 40px 0 20px;
          }
          &:first-child {
            margin-top: 0;
            @include max($md) {
              margin-top: 50px;
            }
            @include max($sm) {
              margin-top: 40px;
            }
          }
        }
      }
      .sq-img-3 {
        width: 100%;
        position: relative;
        z-index: 0;
      }
      .sq-img-4 {
        margin: -198px 4px 0 138px;
        position: relative;
        z-index: 1;
        @include max($sm) {
          margin: -70px 0 0 60px;
        }
      }
    }
  }
}
