/***** Homepage *****/

.section-home-bnr {
  background: url("../images/il/bnr-home.jpg") 50% 50% no-repeat;
  background-size: cover;
  overflow: hidden;
  @include max($lg) {
    height: auto;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #061847 0%, rgba(#061847, 0) 100%);
  }
  .container {
    position: relative;
    padding-top: 278px;
    @include max($lg) {
      padding-top: 220px;
    }
    @include max($sm) {
      padding-top: 60px;
    }
  }
  .sq-bnr-1 {
    width: 463px;
    height: 463px;
    position: absolute;
    top: 61px;
    left: -122px;
    color: #001e30;
    @include max($sm) {
      display: none;
    }
    svg {
      opacity: 0.15;
    }
  }
  .sq-bnr-2 {
    width: 468px;
    height: 468px;
    position: absolute;
    bottom: -127px;
    right: -175px;
    color: #01375b;
    @include max($sm) {
      width: 193px;
      height: 193px;
      bottom: -31px;
      right: -76px;
    }
    svg {
      opacity: 0.14;
    }
  }
}

.section-home-intro {
  background: $blue;
  color: #fff;
  position: relative;
  z-index: 1;
  .container {
    max-width: 1004px;
    position: relative;
  }
  h2 {
    padding-bottom: 35px;
    margin: 0 0 35px;
    border-bottom: 1px solid rgba(#fff, 0.5);
    color: #fff;
  }
  .home-intro-i {
    padding: 125px 0 115px;
    @include max($lg) {
      padding: 80px 0 120px;
    }
    @include max($sm) {
      padding: 40px 0 70px;
    }
  }
  .home-intro-txt {
    font-size: 18px;
    @include max($lg) {
      font-size: 16px;
    }
    @include max($sm) {
      font-size: 14px;
    }
    // .row {
    //   margin: 0 -20px;
    //   [class*="col-"] {
    //     padding: 0 20px;
    //   }
    // }
  }

  .sq-white {
    position: absolute;
    width: 45px;
    height: 45px;
    color: #fff;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .sq-1 {
    width: 391px;
    height: 391px;
    color: $blue4;
    position: absolute;
    z-index: -1;
    top: -52px;
    left: -43px;
    opacity: 0.12;
  }
  .sq-img {
    position: absolute;
    bottom: -330px;
    right: -395px;
    width: 529px;
    height: 529px;
    @include max($xxxl) {
      right: -120px;
      width: 350px;
      height: 350px;
      bottom: -230px;
    }
    @include max($lg) {
      width: 244px;
      height: 244px;
      right: -30px;
      bottom: -190px;
    }
  }
}

.section-home-sectors {
  background: $blueb;
  position: relative;
  z-index: 0;
  color: #fff;
  .container {
    position: relative;
    max-width: 1004px;
  }
  .home-sectors-i {
    padding: 125px 0 570px;
    position: relative;
    z-index: 1;
    @include max($lg) {
      padding: 180px 0 500px;
    }
    @include max($sm) {
      padding: 200px 0 400px;
    }
  }
  h2 {
    padding-bottom: 35px;
    color: #fff;
    margin: 0 0 85px;
    border-bottom: 1px solid rgba(#fff, 0.5);
  }

  .sectors-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include max($lg) {
      display: block;
      margin: 0;
    }
    .item {
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      padding: 0 20px 45px;
      @include max($lg) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
      @include max($sm) {
        margin-bottom: 45px;
        position: relative;
      }
      .item-icon {
        background: url("../images/el/square-lblue.svg") 50% 50% no-repeat;
        background-size: contain;
        width: 169px;
        flex: 0 0 169px;
        height: 169px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 38px;
        .icon {
          width: 80px;
          height: 80px;
        }
      }
      .item-title {
        flex: 1;
        font-size: 24px;
        font-weight: 600;
        @include max($sm) {
          position: absolute;
          max-width: 60%;
          padding: 0;
          bottom: 0;
        }
      }
      &:nth-child(2n) {
        .item-icon {
          @include max($lg) {
            margin-left: 150px;
          }
          @include max($sm) {
            margin: 0 0 0 auto;
          }
        }
        .item-title {
          @include max($sm) {
            left: 0;
          }
        }
      }
      &:nth-child(2n + 1) {
        .item-icon {
          @include max($sm) {
            margin-right: auto;
          }
        }
        .item-title {
          @include max($sm) {
            right: 0;
            text-align: right;
          }
        }
      }
    }
  }

  .sq-1,
  .sq-2 {
    width: 799px;
    height: 799px;
    position: absolute;
    color: #fff;
    opacity: 0.07;
  }
  .sq-1 {
    top: -99px;
    right: -536px;
  }
  .sq-2 {
    bottom: 197px;
    left: -566px;
  }
}

.section-home-projects {
  position: relative;
  z-index: 1;
  margin-top: -574px;
  @include max($lg) {
    // margin-top: -400px;
  }
  .container {
    position: relative;
    z-index: 2;
    display: flex;
    @include max($sm) {
      display: block;
    }
  }
  .home-projects-i {
    margin-left: auto;
    position: relative;
    z-index: 1;
    width: 53%;
    flex: 0 0 53%;
    padding: 373px 0 865px;
    @include max($lg) {
      padding: 350px 0 500px;
    }
    @include max($sm) {
      width: 100%;
      padding: 480px 0 500px;
    }
  }
  h2 {
    border-bottom: 1px solid rgba($blued, 0.5);
    padding-bottom: 35px;
    margin: 0 0 40px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      height: 1px;
      left: 100%;
      width: 100vw;
      background: rgba($blued, 0.5);
    }
  }
  .desc {
  }

  .sqs {
    position: absolute;
    z-index: 1;
    background: url("../images/el/squares-bg.svg") 50% 50% no-repeat;
    background-size: contain;
    top: 56px;
    width: 2282px;
    height: 1331px;
    bottom: 396px;
    left: 50%;
    transform: translateX(-47%);
    @include max($xxxl) {
      // width: 120vw;
      // height: 70vw;
      background-size: auto 80%;
    }
  }
  .sq-img.sq-img-1 {
    position: absolute;
    top: 0;
    left: -170px;
    width: 678px;
    height: 678px;
    @include max($lg) {
      width: 400px;
      height: 400px;
      left: -100px;
    }
    @include max($sm) {
      width: 300px;
      height: 300px;
      left: auto;
      right: -80px;
      top: 200px;
    }
  }
  .sq-img.sq-img-2 {
    position: absolute;
    bottom: 466px;
    left: 152px;
    width: 508px;
    height: 508px;
    @include max($xxxl) {
      width: 300px;
      height: 300px;
      left: 50px;
    }
    @include max($lg) {
      left: -20px;
      width: 200px;
      height: 200px;
      bottom: 100px;
    }
  }
  .sq-img.sq-img-3 {
    position: absolute;
    bottom: 542px;
    right: 107px;
    width: 453px;
    height: 453px;
    @include max($xxxl) {
      width: 300px;
      height: 300px;
      left: 600px;
      right: auto;
    }
    @include max($lg) {
      left: 240px;
      right: auto;
      width: 200px;
      height: 200px;
      bottom: 200px;
    }
  }
  .section-bg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    top: 574px;
    background: url("../images/il/bnr-home-3.jpg") 50% 50% no-repeat;
    background-size: cover;
  }
}
