/***** Footer *****/

.footer {
  margin-top: auto;
  position: relative;
  z-index: 1;
  .footer-t {
    background: $blue;
    z-index: 1;
    position: relative;
    // margin-bottom: -155px;
    .container {
      position: relative;
    }
    .footer-t-i {
      display: flex;
      align-items: stretch;
      .footer-logo {
        position: absolute;
        top: 105px;
        left: 30px;
        width: 305px;
        z-index: 1;
        @include max($lg) {
          width: 161px;
          flex: 0 0 161px;
          position: relative;
          padding: 15px 0 0;
          top: auto;
          left: auto;
        }
        img {
          width: 100%;
        }
      }
      .footer-nav {
        margin: 0 60px 0 auto;
        min-width: 120px;
        position: relative;
        @include max($xxxl) {
          margin: 0 0 0 auto;
        }
        @include max($lg) {
          margin: 0 0 0 auto;
          padding: 40px 0 40px;
        }
        @include max($sm) {
          padding: 80px 0 35px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 1px;
          left: 100%;
          width: 100vw;
          background: rgba(#fff, 0.5);
          @include max($lg) {
            display: none;
          }
        }
        ul {
          border-bottom: 1px solid rgba(#fff, 0.5);
          margin: 0;
          list-style: none;
          display: flex;
          align-items: center;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 34px;
          padding: 0;
          @include max($lg) {
            border: none;
            display: block;
            text-align: right;
          }
          li {
            display: block;
            &:not(:last-child) {
              @include max($lg) {
                margin-bottom: 15px;
              }
              @include max($sm) {
                margin-bottom: 10px;
              }
            }
            a,
            span {
              display: block;
              padding: 38px;
              line-height: 20px;
              letter-spacing: 0.04em;
              @include max($xxxl) {
                padding: 30px 20px;
                letter-spacing: 0;
              }
              @include max($lg) {
                padding: 15px 0;
              }
              @include max($sm) {
                padding: 12px 0;
              }
            }
            a {
              color: #fff;
              &:hover {
                opacity: 0.8;
              }
            }
            span {
              color: #fff;
              cursor: default;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                display: block;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 5px;
                background: #fff;
                @include max($lg) {
                  width: 115px;
                  left: auto;
                  right: 0;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .footer-bg-l {
      width: 628px;
      height: 427px;
      background: url("../images/el/footer-l-bg.svg") 0 0 no-repeat;
      background-size: 100% auto;
      position: absolute;
      z-index: 0;
      top: -170px;
      left: -195px;
      @include max($xxxl) {
        height: 411px;
      }
      @include max($lg) {
        width: 330px;
        height: 330px;
        top: -165px;
        bottom: auto;
        left: -93px;
      }
    }
  }

  .footer-b {
    background: $blue4;
    z-index: 0;
    position: relative;
    color: #fff;
    font-size: 15px;
    a {
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
    .container {
    }
    .footer-b-i {
      position: relative;
      display: flex;
      align-items: flex-start;
      min-height: 160px;
      padding-left: 545px;
      @include max($xxxl) {
        padding-left: 450px;
      }
      @include max($lg) {
        padding: 0;
      }

      .footer-b-info {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        padding: 40px 0;
        z-index: 1;
        @include max($sm) {
          display: block;
        }
        .footer-b-contacts {
          width: 70%;
          flex: 0 0 70%;
          padding-right: 30px;
          @include max($sm) {
            width: 100%;
            padding: 0 0 45px;
          }
          a {
          }
        }
        .footer-b-copyright {
          width: 30%;
          flex: 0 0 30%;
          @include max($sm) {
            width: 100%;
          }
          br {
            @include max($sm) {
              display: none;
            }
          }
        }
      }
    }
  }

  .footer-up {
    position: absolute;
    top: -121px;
    right: 80px;
    width: 55px;
    height: 55px;
    cursor: pointer;
    transition: all 0.2s;
    @include max($lg) {
      right: 30px;
      top: -90px;
    }
    @include max($sm) {
      right: 18px;
    }
    &:hover {
      opacity: 0.8;
    }
    .icon {
      width: 55px;
      height: 55px;
      path {
        &:first-child {
          opacity: 0.2;
        }
      }
    }
  }
}
