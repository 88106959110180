/***** Simple elements, atoms *****/

.icon-w {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icon {
    // fill: currentColor;
    max-height: 100%;
    max-width: 100%;
  }
}

.sq-svg {
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    path {
    }
  }
}

ul.bsq-list,
.txt ul {
  margin: 50px 0;
  padding: 0;
  font-size: 24px;
  font-weight: 300;
  list-style: none;
  @include max($lg) {
    font-size: 18px;
    margin: 30px 0;
  }
  @include max($sm) {
    font-size: 14px;
    margin: 20px 0;
  }
  li {
    position: relative;
    padding: 2px 0 2px 64px;
    @include max($lg) {
      padding: 2px 0 2px 36px;
    }
    @include max($sm) {
      padding: 2px 0 2px 30px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 8px;
      width: 29px;
      height: 29px;
      background: url("../images/el/square-blue.svg") 0 0 no-repeat;
      background-size: contain;
      @include max($lg) {
        width: 21px;
        height: 21px;
        top: 6px;
      }
      @include max($sm) {
        width: 17px;
        height: 17px;
        top: 4px;
      }
    }
  }
}

.txt ol {
  list-style: none;
  counter-reset: sqcounter;
  padding: 0;
  margin: 40px 0;
  @include max($lg) {
    margin: 30px 0;
  }
  @include max($sm) {
    margin: 20px 0;
  }
  li {
    counter-increment: sqcounter;
    position: relative;
    padding: 2px 0 2px 64px;
    @include max($lg) {
      padding-left: 48px;
    }
    @include max($sm) {
      padding-left: 40px;
    }
    &:not(:last-child) {
      margin-bottom: 45px;
      @include max($lg) {
        margin-bottom: 30px;
      }
      @include max($sm) {
        margin-bottom: 20px;
      }
    }

    &:before {
      content: counter(sqcounter);
      font-size: 32px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 8px;
      width: 54px;
      height: 54px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23015993" viewBox="0 0 999.29 999.34"><path d="M533.75,985.21a48.16,48.16,0,0,1-68.16,0L14.13,533.75a48.25,48.25,0,0,1,0-68.23L465.59,14.13a48.16,48.16,0,0,1,68.16,0L985.21,465.52a48.38,48.38,0,0,1,0,68.23Z"/></svg>')
        50% 50% no-repeat;
      @include max($lg) {
        width: 40px;
        height: 40px;
        font-size: 24px;
      }
      @include max($sm) {
        width: 32px;
        height: 32px;
        font-size: 20px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 10px;
      line-height: 1.1;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
