/***** Governance *****/

.section-governance-bnr {
  background: url("../images/il/bnr-governance.jpg") 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  @include max($lg) {
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-top: 278px;
  }
}

.section-governance {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 165px;
  .container {
  }
  .governance-i {
  }

  .governance-body {
    padding: 135px 0 0;
    @include max($lg) {
      padding: 60px 0 0;
    }
    @include max($sm) {
      padding: 30px 0 0;
    }
  }
  .governance-t {
    margin-bottom: 142px;
    position: relative;
    @include max($lg) {
      margin-bottom: 100px;
    }
    @include max($sm) {
      margin-bottom: 80px;
    }
    h1 {
      color: $blue;
      margin-bottom: 65px;
      @include max($lg) {
        margin-bottom: 40px;
      }
      @include max($sm) {
        margin-bottom: 30px;
      }
    }
    .brief {
      font-size: 30px;
      font-weight: 300;
      max-width: 960px;
      line-height: 1.25;
      @include max($lg) {
        font-size: 25px;
        max-width: 700px;
      }
      @include max($sm) {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }
    .sq-1 {
      width: 1118px;
      height: 1118px;
      color: #f3f8fa;
      opacity: 0.7;
      position: absolute;
      z-index: -1;
      left: -485px;
      top: -592px;
      @include max($sm) {
        width: 474px;
        height: 474px;
        top: -97px;
        left: -157px;
      }
    }
    .sq-2 {
      width: 41px;
      height: 41px;
      color: $blue;
      opacity: 0.1;
      position: absolute;
      right: 65px;
      top: 275px;
      @include max($sm) {
        top: 260px;
        right: 0;
      }
    }
  }

  .governance-b {
    .governance-info {
      padding-left: 25%;
      @include max($lg) {
        padding: 0;
      }
      h2 {
        color: $blue;
        margin-bottom: 50px;
        @include max($lg) {
          margin-bottom: 40px;
        }
        @include max($sm) {
          margin-bottom: 30px;
        }
      }
      .txt {
      }
      &.governance-info-1 {
        margin-bottom: 140px;
        @include max($lg) {
          margin-bottom: 100px;
        }
        @include max($sm) {
          margin-bottom: 80px;
        }
      }
      &.governance-info-2 {
      }
    }
    .governance-board {
      position: relative;
      margin-bottom: 150px;
      @include max($lg) {
        margin-bottom: 100px;
      }
      @include max($sm) {
        margin-bottom: 80px;
      }
      h2 {
        padding-left: 25%;
        color: $blue;
        margin-bottom: 90px;
        @include max($xl) {
          // padding-left: 0;
          margin-bottom: 40px;
        }
        @include max($md) {
          padding: 0 200px 0 0;
        }
        @include max($sm) {
          margin-bottom: 30px;
          padding: 0 130px 0 0;
        }
      }

      .h2-w {
        position: relative;
        @include max($lg) {
          width: 300px;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: -150px 0 -100px -90px;
        }
        @include max($md) {
          width: 250px;
          height: 250px;
          position: absolute;
          right: -80px;
          top: -50px;
          margin: 0;
        }
        @include max($sm) {
          width: 180px;
          height: 180px;
          right: -50px;
          top: -20px;
        }
        .h2 {
          color: #fff;
          z-index: 1;
          position: relative;
          margin: 0;
          @include max($lg) {
            text-align: center;
          }
          @include max($md) {
            font-size: 20px;
          }
        }
        .sq-svg.sq-3 {
          width: 800px;
          height: 800px;
          position: absolute;
          z-index: -1;
          top: 0;
          right: calc(75% + 20px);
          transform: translateY(-46%);
          // margin-top: -22px;
          color: #015993;
          @include max($lg) {
            width: 100%;
            height: 100%;
            right: 0;
            transform: none;
          }
        }
      }

      .person {
        position: relative;
        padding: 0;
        margin: 0 0 55px 390px;
        @include max($xl) {
          margin-left: 0;
        }
        @include max($lg) {
          margin: 0 0 40px;
        }
        @include max($sm) {
          margin-bottom: 60px;
        }
        &.person-1 {
          @include max($xl) {
            margin-left: 300px;
          }
          @include max($lg) {
            margin-left: 100px;
          }
          @include max($md) {
            margin-left: 0;
          }
        }
        .person-photo {
          width: 400px;
          height: 400px;
          position: relative;
          z-index: 0;
          @include max($xxxl) {
            width: 300px;
            height: 300px;
          }
          @include max($md) {
            width: 200px;
            height: 200px;
          }
          @include max($sm) {
            width: 180px;
            height: 180px;
            margin-left: -20px;
          }
        }
        .person-info {
          position: absolute;
          bottom: 0;
          left: 292px;
          z-index: 1;
          padding-left: 49px;
          line-height: 1.4;
          @include max($xxxl) {
            left: 240px;
          }
          @include max($md) {
            left: 170px;
          }
          @include max($sm) {
            left: 100px;
            bottom: 0;
            top: 150px;
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 3px;
            width: 29px;
            height: 29px;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 999.29 999.34" fill="%234E93BB"><path d="M533.75,985.21a48.16,48.16,0,0,1-68.16,0L14.13,533.75a48.25,48.25,0,0,1,0-68.23L465.59,14.13a48.16,48.16,0,0,1,68.16,0L985.21,465.52a48.38,48.38,0,0,1,0,68.23Z"/></svg>')
              50% 50% no-repeat;
            background-size: contain;
          }
          .person-name {
            color: $blue4;
            font-size: 24px;
            @include max($md) {
              font-size: 20px;
            }
            @include max($sm) {
              font-size: 18px;
            }
          }
          .person-position {
            font-size: 24px;
            font-weight: 300;
            @include max($md) {
              font-size: 20px;
            }
            @include max($sm) {
              font-size: 16px;
            }
          }
        }
      }
      .person-row {
        display: flex;
        margin: 0 -60px 60px 60px;
        @include max($xxxl) {
          margin: 0 0 40px 0;
        }
        @include max($xl) {
          display: block;
        }

        .person {
          margin-left: 0;
          padding-right: 20px;
          width: 50%;
          flex: 0 0 50%;
          @include max($xl) {
            width: 100%;
            padding: 0;
          }
          &:nth-child(2) {
            @include max($xl) {
              margin-left: 300px;
            }
            @include max($lg) {
              margin-left: 100px;
            }
            @include max($md) {
              margin: 0;
            }
          }
        }
      }

      .sq-svg {
        &.sq-4 {
          color: #94c4d5;
          width: 55px;
          height: 55px;
          position: absolute;
          top: calc(50% - 45px);
          left: calc(50% - 40px);
          z-index: 0;
          @include max($lg) {
            left: auto;
            right: 0;
          }
        }
        &.sq-5 {
          width: 134px;
          height: 134px;
          color: $blue;
          opacity: 0.1;
          position: absolute;
          top: calc(50% + 80px);
          left: calc(50% - 120px);
          z-index: 0;
          @include max($lg) {
            left: auto;
            right: 0;
          }
        }
        &.sq-6 {
          width: 1198px;
          height: 1198px;
          color: #f3f8fa;
          position: absolute;
          z-index: -1;
          right: -760px;
          top: 390px;
        }
      }
    }
    .page-more-link {
      margin-top: 20px;
    }
  }
}
